import { render, staticRenderFns } from "./productCenter.vue?vue&type=template&id=6a77a752&scoped=true&"
import script from "./productCenter.vue?vue&type=script&lang=ts&"
export * from "./productCenter.vue?vue&type=script&lang=ts&"
import style0 from "./productCenter.vue?vue&type=style&index=0&id=6a77a752&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a77a752",
  null
  
)

export default component.exports